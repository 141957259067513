<template>
    <div class="pb-5">
        <v-title title="Company profiles"></v-title>
        <title-box>
            <div class="row">
                <div class="col-sm-9">
                    <h1>Company Profiles</h1>
                </div>

                <div class="col-sm-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createCompanyProfile')" @click.native="triggerAdd" class="btn btn-primary text-uppercase">
                        + Add
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listCompanyProfiles')">
                <div>
                    <template v-if="selectedShop && selectedShop.applicationId !== '0'">
                        <div class="row mt-3">
                            <div class="col-6">
                                <quick-search :quickSearch.sync="filters.search" />
                            </div>
                            <div class="col-6">
                                <pagination :pagination="pagination" @change="changeAmountOfItems" />
                            </div>
                        </div>

                        <merchant-data-table class="shadow" :options="options" :url="url" :quickSearch="filters.search" ref="table" @paginationSet="updatePagination"></merchant-data-table>
                    </template>
                    <mercur-card class="mt-n4 mb-4" v-else>
                        <p>Select a shop in order to see company profiles</p>
                    </mercur-card>
                </div>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>

            <div>
                <mercur-dialog :is-open.sync="removeDialog.isOpen">
                    <p>{{ removeDialog.content }}</p>
                    <template #footer>
                        <mercur-button class="btn" @click.native="removeDialog.isOpen = false">Cancel</mercur-button>
                        <mercur-button class="btn btn-primary" @click.native="confirmRemove">Confirm</mercur-button>
                    </template>
                </mercur-dialog>
            </div>

            <div>
                <mercur-dialog :is-open.sync="dialog.isOpen" width="800px">
                    <h2 class="font-weight-normal">{{ dialog.isEdit ? 'Edit' : 'Add' }} company profile</h2>
                    <form-generator @submit="submit" @cancel="dialog = {}" :data="dialog.data" :fields="formFields" :url="dialog.url">
                        <template #default="slotData">
                            <div class="col-12">
                                <dropdown-card title="Account View Config" class="account-view">
                                    <div class="row">
                                        <div class="col-6" v-for="field in accountViewFields" :key="field.key">
                                            <mercur-input :id="field.key" v-model="slotData.form.accountViewConfig[field.key]">
                                                {{ field.label }}
                                            </mercur-input>
                                        </div>
                                    </div>
                                </dropdown-card>
                            </div>
                        </template>
                    </form-generator>
                </mercur-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'
import FormGenerator from '@/components/utils/FormGenerator'
import DropdownCard from '../../components/elements/DropdownCard'
export default {
    name: 'CompanyProfiles',
    components: { MerchantDataTable, Pagination, QuickSearch, FormGenerator, DropdownCard },
    data () {
        return {
            url: CONFIG.API.ROUTES.VAT_CONFIG.COMPANY_PROFILES.LIST,
            pagination: {},
            amountOfDisplayedItems: 50,
            removeDialog: {},
            dialog: {},
            formFields: [
                { key: 'companyName', label: 'Company Name', required: true },
                { key: 'companyProfileLabel', label: 'Label', required: true },
                { key: 'companyCocNumber', label: 'Coc Number', required: true },
                { key: 'companyAddress', label: 'Address', required: true },
                { key: 'companyZipCode', label: 'Zip Code' },
                { key: 'companyCity', label: 'City', required: true },
                { key: 'companyCountry', label: 'Country', required: true, country: true },
                { key: 'companyWebsite', label: 'Website', required: true },
                { key: 'companyPhone', label: 'Phone', required: true },
                { key: 'companyEmail', label: 'Email', required: true },
                { key: 'companyIban', label: 'IBAN', required: true },
                { key: 'companyBic', label: 'BIC', required: true },
                { key: 'companyBankName', label: 'Bank Name', required: true },
                { key: 'companyBankAccountHolder', label: 'Account Holder', required: true },
            ],
            accountViewFields: [
                { key: 'PS_ACCOUNTVIEW_ADMIN', label: 'Admin' },
                { key: 'PS_ACCOUNTVIEW_APP_ID', label: 'App Id' },
                { key: 'PS_ACCOUNTVIEW_APP_SERVER', label: 'App Server' },
                { key: 'PS_ACCOUNTVIEW_DEFAULT_ACCT_NR', label: 'Default Account Number' },
                { key: 'PS_ACCOUNTVIEW_DEFAULT_JOURNAL', label: 'Default Journal' },
                { key: 'PS_ACCOUNTVIEW_ORDERSLIPPREFIX', label: 'OrderSlip Prefix' },
                { key: 'PS_ACCOUNTVIEW_DEFAULT_COST_CODE', label: 'Default Cost Code' },
                { key: 'PS_ACCOUNTVIEW_DEFAULT_DISC_CODE', label: 'Default Disc Code' },
            ],
            options: {
                columns: {
                    'Company Name': { field: 'companyName' },
                    'company Bic': { field: 'companyBic' },
                    'companyProfileId': { field: 'companyProfileId', hide: true },
                    'Email': { field: 'companyEmail', type: 'email' },
                    'Phone': { field: 'companyPhone' },
                    'Address': { field: 'companyAddress' },
                    'Zip code': { field: 'companyZipCode', width: 120 },
                    'city': { field: 'companyCity' },
                    'Country': { field: 'companyCountry', width: 120 },
                    'Website': { field: 'companyWebsite' },
                    'Bank': { field: 'companyBankName', width: 120 },
                    'Iban': { field: 'companyIban' },
                    'Coc Number': { field: 'companyCocNumber' },
                    'Profile Label': { field: 'companyProfileLabel' },
                    'Bank Account Holder': { field: 'companyBankAccountHolder' },
                    'Date Updated': { field: 'dateUpdated', hide: true, sortable: true },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            onClick: ({ data }) => {
                                this.triggerEdit(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/updateCompanyProfile'),
                        },
                        {
                            text: 'Delete',
                            onClick: ({ data }) => {
                                this.triggerRemove(data)
                            },
                            isHidden: () => !this.hasPermission('MerchantCentral/removeCompanyProfile'),
                        },
                    ],
                },
                quickSearchColumns: ['companyCountry', 'companyProfileId', 'companyProfileLabel', 'companyEmail', 'companyCocNumber', 'companyName'],
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
            },
            filters: {
                search: '',
            },
        }
    },
    methods: {
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
        triggerRemove (data) {
            this.removeDialog = {
                isOpen: true,
                content: `Are you sure you want to remove this company profile?`,
                data: data,
            }
        },
        confirmRemove () {
            const url = CONFIG.API.ROUTES.VAT_CONFIG.COMPANY_PROFILES.REMOVE.replace('{companyProfileId}', this.removeDialog.data.companyProfileId)
            this.post(url, {}, 'Company profile removed').then(({ data }) => {
                this.removeDialog = {}
                this.$refs.table.refreshGrid()
            })
        },
        triggerEdit (data) {
            if (!data.accountViewConfig) {
                data.accountViewConfig = {}
            }

            this.dialog = {
                isOpen: true,
                isEdit: true,
                data: data,
                url: CONFIG.API.ROUTES.VAT_CONFIG.COMPANY_PROFILES.UPDATE,
            }
        },
        submit () {
            this.dialog = {}
            this.$refs.table.refreshGrid()
            this.$root.$emit('notification:global', {
                message: 'Company profile was added / updated',
            })
        },
        triggerAdd () {
            this.dialog = {
                isOpen: true,
                data: {
                    accountViewConfig: {},
                },
                url: CONFIG.API.ROUTES.VAT_CONFIG.COMPANY_PROFILES.CREATE,
            }
        },
    },
}

</script>

<style lang="scss" scoped>
    .account-view {
        margin-bottom: 20px;
        margin-top: 10px;
    }
</style>
